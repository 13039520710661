<template>
  <v-container>
    <v-row class="mt-3">
      <v-col
        cols="12"
        v-if="isTrans == 'true'"
        class="d-flex w-100 justify-content-end"
      >
        <v-btn color="primary" @click="onClickCancel">
          {{ $t("cancel_beneficary") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("firstName") }}<span style="color: #f00">*</span></label>
        <v-text-field
          v-model="beneficiaryReqBody.first_name"
          dense
          :placeholder="$t('firstname_beneficary')"
          required
          :disabled="
            beneficiaryReqBody.payment_code === 'A' &&
            beneficiaryReqBody.policy_number
          "
          :error-messages="firstNameErrors"
          @input="$v.beneficiaryReqBody.first_name.$touch()"
          @blur="$v.beneficiaryReqBody.first_name.$touch()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("lastname_beneficary") }}</label>
        <v-text-field
          v-model="beneficiaryReqBody.last_name"
          :placeholder="$t('lastname_beneficary')"
          dense
          required
          :error-messages="lastNameErrors"
          @input="$v.beneficiaryReqBody.last_name.$touch()"
          @blur="$v.beneficiaryReqBody.last_name.$touch()"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label class="">
          {{ $t("relationship") }}<span style="color: #f00">*</span>
        </label>
        <v-autocomplete
          v-model="SelectedRelationship"
          :items="relation_options"
          item-text="txt"
          item-value="val"
          :error-messages="RelationErrors"
          @input="$v.SelectedRelationship.$touch()"
          :placeholder="$t('relationship_placeholder')"
          outlined
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label :description="$t('optional')">
          {{ $t("phoneNumber") }}<span style="color: #f00">*</span>
        </label>
        <vue-phone-number-input
          :default-country-code="phone_code"
          v-model="beneficiaryReqBody.phone_number"
          :error-messages="phoneErrors"
          @input="$v.beneficiaryReqBody.phone_number.$touch()"
          @blur="$v.beneficiaryReqBody.phone_number.$touch()"
          @update="onChangeNumber"
          :error="!isValidNumber"
          class=""
          required
        />
        <span style="color: #ff5252; font-size: 12px">{{
          phoneErrors[0]
        }}</span>
      </v-col>

      <v-col cols="12" md="6">
        <label :description="$t('optional')">
          {{ $t("emailAddress") }}
        </label>
        <v-text-field
          :placeholder="$t('email_placeholder')"
          dense
          type="email"
          required
          v-model="beneficiaryReqBody.email_id"
          :error-messages="emailErrors"
          @input="$v.beneficiaryReqBody.email_id.$touch()"
          @blur="$v.beneficiaryReqBody.email_id.$touch()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("address") }}<span style="color: #f00">*</span></label>
        <v-text-field
          v-model="beneficiaryReqBody.address"
          :error-messages="addressErrors"
          :placeholder="$t('enter_address_beneficary')"
          @input="$v.beneficiaryReqBody.address.$touch()"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label class="mt-1"
          >{{ $t("country") }}<span style="color: #f00">*</span></label
        >
        <v-autocomplete
          v-model="countrySelected"
          :placeholder="$t('select_country_beneficary')"
          :items="country_options"
          disabled
          item-text="txt"
          item-value="val"
          :error-messages="countryErrors"
          @input="
            $v.countrySelected.$touch();
            onChangeCounty($event);
          "
          outlined
          required
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" v-if="selcted_pay_mode === 'C'">
        <label>{{ $t("Province") }}</label>
        <v-autocomplete
          v-model="stateSelected"
          :items="state_options"
          item-text="txt"
          item-value="val"
          @change="onChangeState($event)"
          :placeholder="$t('province_placeholder')"
          outlined
          required
          dense
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" v-if="selcted_pay_mode === 'C'">
        <label class="mt-1">{{ $t("city") }}</label>
        <v-autocomplete
          v-if="haveCities"
          v-model="citySelected"
          :items="city_options"
          item-text="txt"
          item-value="val"
          :placeholder="$t('city_placeholder_beneficary')"
          outlined
          required
          dense
        >
        </v-autocomplete>
        <v-text-field
          v-else
          v-model="cityValue"
          :placeholder="$t('enter_city')"
          outlined
          required
          dense
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6" v-if="selcted_pay_mode === 'C'">
        <label>{{ $t("postalCode") }}</label>
        <v-text-field
          v-model="beneficiaryReqBody.postal_code"
          :placeholder="$t('postalcode_placeholder_beneficary')"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="bene_country === 'Myanmar'">
        <label
          >{{ $t("id_type_beneficary")
          }}<span style="color: #f00">*</span></label
        >
        <v-select
          v-model="beneficiaryReqBody.id_type"
          :placeholder="$t('id_type_placeholder')"
          :error-messages="idTypeErrors"
          :items="idtypes"
          item-text="text"
          item-value="value"
          @input="$v.beneficiaryReqBody.id_type.$touch()"
          @blur="$v.beneficiaryReqBody.id_type.$touch()"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" v-if="bene_country === 'Myanmar'">
        <label>{{ $t("IdNumber") }}<span style="color: #f00">*</span></label>
        <v-text-field
          v-model="beneficiaryReqBody.id_no"
          :placeholder="$t('idnumber_placeholder_beneficary')"
          :error-messages="idNumberErrors"
          @input="$v.beneficiaryReqBody.id_no.$touch()"
          @blur="$v.beneficiaryReqBody.id_no.$touch()"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickBack"
          ><v-icon dark left>mdi-arrow-left</v-icon>
          {{ $t("backLabel") }}</v-btn
        >
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickNext"
          >{{ $t("next") }} <v-icon dark right>mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import {
  required,
  email,
  requiredIf,
  // alphaNum,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { alpha } from "../../../utils/validate";
import commonService from "@/services/CommonService/commonService";
export default {
  name: "BasicDetails",
  data: () => ({
    country_options: [],
    state_options: [],
    city_options: [],
    isSubmitted: false,
    countrySelected: {},
    stateSelected: {},
    citySelected: {},
    SelectedRelationship: {},
    currentDate: new Date().toJSON().split("T")[0],
    isValidNumber: false,
    relation_options: [],
    occupation_options: [],
    occupation_objs: [],
    idtypes: [],
    idtype_objs: [],
    recieverDetails: {},
    genderOptions: [
      { text: "Male", value: "M" },
      { text: "Female", value: "F" },
    ],
    selcted_pay_mode: "",
    requiredVal: "*",
    country_code: {},
    // country_options: [],
    cityValue: "",
    haveCities: true,
    country_cd: "",
    phone_code: "",
    country_objs: [],
  }),
  props: {
    beneficiaryReqBody: {
      type: Object,
      required: true,
    },
    getrecieverDetails: {
      type: Object,
      required: true,
    },
    bene_country: String,
    payment_method_selected: String,
    isTrans: {
      type: String,
      default: "",
    },
  },
  watch: {
    getrecieverDetails: {
      handler(newVal) {
        this.recieverDetails = newVal;
        this.beneficiaryReqBody.first_name = this.recieverDetails.first_name
          ? this.recieverDetails.first_name
          : "";
        this.beneficiaryReqBody.last_name = this.recieverDetails.last_name
          ? this.recieverDetails.last_name
          : "";
        this.beneficiaryReqBody.email_id = this.recieverDetails.email_id
          ? this.recieverDetails.email_id
          : null;
        this.beneficiaryReqBody.address = this.recieverDetails.address
          ? this.recieverDetails.address
          : "";
        this.beneficiaryReqBody.postal_code = this.recieverDetails.postal_code
          ? this.recieverDetails.postal_code
          : "";
        this.beneficiaryReqBody.id_no = this.recieverDetails.id_no
          ? this.recieverDetails.id_no
          : "";
        this.beneficiaryReqBody.id_type = this.recieverDetails.id_type
          ? this.recieverDetails.id_type
          : "";
        console.log("new", this.recieverDetails);
        if (
          this.beneficiaryReqBody.bank_account_no &&
          this.beneficiaryReqBody.payment_mode == "W"
        ) {
          this.beneficiaryReqBody.phone_number = this.beneficiaryReqBody
            .bank_account_no
            ? this.beneficiaryReqBody.bank_account_no
            : "";
        } else {
          this.beneficiaryReqBody.phone_number = this.recieverDetails
            .phone_number
            ? this.recieverDetails.phone_number
            : "";
        }
      },
    },
    // getting country name from account section and mapped to the bene country
    bene_country: {
      handler(newval) {
        if (newval) {
          this.country_options.map((i) => {
            if (i.txt === newval) {
              console.log("i", i);
              this.countrySelected = i;
              this.onChangeCounty(i.val);
              this.country_code = i.val;
            }
          });
          this.country_objs.map((i) => {
            if (i.name === newval) {
              this.phone_code = i.iso2;
            }
          });
        }
      },
    },
    payment_method_selected: {
      handler(newval) {
        if (newval) {
          this.selcted_pay_mode = newval;
          if (newval != "C") {
            this.isValidNumber = true;
            this.requiredVal = "";
          } else {
            this.requiredVal = "*";
          }
          console.log("this.selcted_pay_mode", this.selcted_pay_mode);
        }
      },
    },

    countrySelected(newVal) {
      console.log("country :", newVal);
      this.beneficiaryReqBody.country_code = newVal.val.iso3
        ? newVal.val.iso3
        : newVal.iso3;
      this.beneficiaryReqBody.country_name = newVal.val.name
        ? newVal.val.name
        : newVal.name;
    },
    stateSelected(newVal) {
      console.log("state values: ", newVal);
      this.beneficiaryReqBody.state_code = newVal.state_code;
      this.beneficiaryReqBody.state_name = newVal.state_name;
    },
    citySelected(newVal) {
      this.beneficiaryReqBody.city_code = newVal.id;
      this.beneficiaryReqBody.city_name = newVal.name;
    },
    SelectedRelationship(newVal) {
      this.beneficiaryReqBody.relationship_code = newVal.code;
      this.beneficiaryReqBody.relationship_name = newVal.name;
    },
    cityValue(newVal) {
      this.beneficiaryReqBody.city_name = newVal;
    },
  },
  components: {
    VuePhoneNumberInput,
  },
  computed: {
    ...mapGetters("Transactional", [
      "realtion_list",
      "occupation_list,idtype_list",
    ]),
    ...mapGetters("Authentication", ["country_list"]),
    ...mapGetters("Authentication", [
      "country_list",
      "state_list",
      "city_list",
    ]),
    countryErrors() {
      const errors = [];
      if (!this.$v.countrySelected.$dirty) return errors;
      !this.$v.countrySelected.required &&
        errors.push(this.$t("select_country"));
      return errors;
    },

    addressErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.address.$dirty) return errors;
      !this.$v.beneficiaryReqBody.address.required &&
        errors.push(this.$t("address"));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.first_name.$dirty) return errors;
      !this.$v.beneficiaryReqBody.first_name.required &&
        errors.push(this.$t("first_name"));
      !this.$v.beneficiaryReqBody.first_name.alpha &&
        errors.push(this.$t("invalid_first_name"));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.last_name.$dirty) return errors;
      // !this.$v.beneficiaryReqBody.last_name.required &&
      //   errors.push(this.$t("last_name"));
      !this.$v.beneficiaryReqBody.last_name.alpha &&
        errors.push(this.$t("invalid_last_name"));
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.email_id.$dirty) return errors;
      // !this.$v.beneficiaryReqBody.email_id.required &&
      //   errors.push(this.$t("email_id"));
      !this.$v.beneficiaryReqBody.email_id.email &&
        errors.push(this.$t("invalid_email"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.phone_number.$dirty) return errors;
      !this.$v.beneficiaryReqBody.phone_number.required &&
        errors.push(this.$t("phone_num"));
      return errors;
    },

    RelationErrors() {
      const errors = [];
      if (!this.$v.SelectedRelationship.$dirty) return errors;
      !this.$v.SelectedRelationship.required &&
        errors.push(this.$t("select_Relation"));
      return errors;
    },
    idNumberErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.id_no.$dirty) return errors;
      !this.$v.beneficiaryReqBody.id_no.required &&
        errors.push(this.$t("idnumber_placeholder_beneficary"));
      // !this.$v.beneficiaryReqBody.id_no.alphaNum &&
      //   errors.push(this.$t("idnumber_valid"));
      return errors;
    },
    idTypeErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.id_type.$dirty) return errors;
      !this.$v.beneficiaryReqBody.id_type.required &&
        errors.push(this.$t("id_type_placeholder"));
      return errors;
    },
  },
  validations() {
    return {
      countrySelected: {
        required,
      },
      SelectedRelationship: {
        required,
      },
      beneficiaryReqBody: {
        address: {
          required,
        },
        first_name: {
          required,
          alpha,
        },
        last_name: {
          alpha,
        },

        phone_number: { required },
        email_id: {
          email,
        },
        id_type: {
          required: requiredIf(function () {
            if (this.bene_country === "Myanmar") return true;
            else return false;
          }),
        },
        id_no: {
          required: requiredIf(function () {
            if (this.bene_country === "Myanmar") return true;
            else return false;
          }),
          // alphaNum,
        },
      },
    };
  },

  mounted() {
    setTimeout(() => {
      this.getRelationship();
      this.getOccupation();
      this.getCountryList();
      this.getidtypes();
      if (this.$route.query.Remittee_ID) {
        this.phone_code = this.beneficiaryReqBody.phone_number_cty;
      } else {
        this.beneficiaryReqBody.phone_number_cty = "IND";
      }
    }, 500);
  },

  methods: {
    onClickCancel() {
      this.$router.push("/transaction/3");
    },
    onChangeNumber(e) {
      console.log("e", e);
      this.isValidNumber = e.isValid;
      this.beneficiaryReqBody.phone_number_cty = e.countryCode;
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // trimming spaces in the number
      let tempNumb = this.beneficiaryReqBody.phone_number.replace(/ /g, "");
      this.beneficiaryReqBody.phone_number = tempNumb;
      this.beneficiaryReqBody.id_type_name = this.idtypes.map((ele) => {
        if (ele.value === this.beneficiaryReqBody.id_type) {
          return ele.text;
        }
      });
      this.$emit("onClickNext", this.beneficiaryReqBody);
      console.log("this.beneficiaryReqBody", this.beneficiaryReqBody);
    },
    async getRelationship() {
      try {
        let responseData = await commonService.getDropdownList("REL");
        if (responseData && responseData.data.status_code == 200) {
          this.relation_options = responseData.data.data.map((i) => {
            return {
              txt: i.value,
              val: {
                name: i.value,
                code: i.value_code,
              },
            };
          });

          //-----------mapping values while updating Beneficiary------------//
          if (this.$route.query.Remittee_ID) {
            this.SelectedRelationship = {
              name: this.recieverDetails.relationship_code.value,
              code: this.recieverDetails.relationship_code.value_code,
            };
          }
        }
      } catch (error) {
        console.log(error);
      }

      // await this.$store.dispatch("Transactional/getRelationship");
      // if (
      //   Array.isArray(this.realtion_list.response.data) &&
      //   this.realtion_list.response.data.length > 0
      // ) {
      //   this.relation_options = await this.realtion_list.response.data.map(
      //     (i) => {
      //       return {
      //         txt: i.value,
      //         val: {
      //           name: i.value,
      //           code: i.value_code,
      //         },
      //       };
      //     }
      //   );

      //   //-----------mapping values while updating Beneficiary------------//
      //   if (
      //     this.$route.query.Remittee_ID &&
      //     Array.isArray(this.relation_options)
      //   ) {
      //     this.SelectedRelationship = {
      //       name: this.recieverDetails.relationship_code.value,
      //       code: this.recieverDetails.relationship_code.value_code,
      //     };
      //   }
      // }
    },
    //to get id types
    async getidtypes() {
      try {
        let responseData = await commonService.getDropdownList("RDOC");
        if (responseData && responseData.data.status_code == 200) {
          responseData.data.data.map((idtype) => {
            this.idtypes.push({
              text: idtype.value,
              value: idtype.value_code,
            });
            this.idtype_objs.push(idtype);
          });
        }
      } catch (error) {
        console.log(error);
      }
      // let response = await this.$store.dispatch("Transactional/getIDtypesMyanmer");
      // console.log("response mynmar", response);
      // response.data.data.map((idtype) => {
      //   this.idtypes.push({
      //     text: idtype.value,
      //     value: idtype.value_code,
      //   });
      //   this.idtype_objs.push(idtype);
      // });
    },
    async getOccupation() {
      try {
        let responseData = await commonService.getDropdownList("OCC");
        if (responseData && responseData.data.status_code == 200) {
          responseData.data.data.map((occupation) => {
            this.occupation_options.push(occupation.value);
            this.occupation_objs.push(occupation);
          });
        }
      } catch (error) {
        console.log(error);
      }
      // let response = await this.$store.dispatch("Transactional/getOccupation");
      // response.data.data.map((occupation) => {
      //   this.occupation_options.push(occupation.value);
      //   this.occupation_objs.push(occupation);
      // });
    },
    async getCountryList() {
      try {
        let responseData = await commonService.getBeneficiaryCountryList();
        if (responseData && responseData.data.status_code == 200) {
          this.country_options = responseData.data.data.map((i) => {
            return {
              val: {
                iso3: i.iso_alpha3,
                name: i.country_name,
              },
              txt: i.country_name,
            };
          });
          this.country_objs = responseData.data.data.map((i) => {
            return {
              iso2: i.iso_alpha2,
              iso3: i.iso_alpha3,
              name: i.country_name,
            };
          });
          //-----------mapping values while updating Beneficiary------------//
          if (this.recieverDetails.country_code.iso_alpha3) {
            this.onChangeCounty({
              iso3: this.recieverDetails.country_code.iso_alpha3,
            });

            // Update in edit mode.
            this.countrySelected = {
              iso3: this.recieverDetails.country_code.iso_alpha3,
              name: this.recieverDetails.country_name,
            };
          }
        }
      } catch (error) {
        console.log(error);
      }
      // await this.$store.dispatch("Authentication/getBeneficiaryCountryList");
      // this.country_options = await this.country_list.country.data.map((i) => {
      //   return {
      //     val: {
      //       // iso2: i.isoAlpha2,
      //       iso3: i.iso_alpha3,
      //       name: i.country_name,
      //     },
      //     txt: i.country_name,
      //   };
      // });
      // this.country_objs = await this.country_list.country.data.map((i) => {
      //   return {
      //     iso2: i.iso_alpha2,
      //     iso3: i.iso_alpha3,
      //     name: i.country_name,
      //   };
      // });
      // //-----------mapping values while updating Beneficiary------------//
      // if (this.recieverDetails.country_code.iso_alpha3) {
      //   this.onChangeCounty({
      //     iso3: this.recieverDetails.country_code.iso_alpha3,
      //   });

      //   // Update in edit mode.
      //   this.countrySelected = {
      //     iso3: this.recieverDetails.country_code.iso_alpha3,
      //     name: this.recieverDetails.country_name,
      //   };
      // }
    },
    async onChangeCounty(country) {
      try {
        const responseData = await commonService.getStateByIso3(country.iso3);
        this.state_options = responseData.data.data.map((i) => {
          return {
            val: {
              state_code: i.state_code,
              state_name: i.name,
            },
            txt: i.name,
          };
        });

        //-----------mapping values while updating Beneficiary------------//
        if (
          this.$route.query.Remittee_ID &&
          this.recieverDetails.state_code.name
        ) {
          this.onChangeState({
            state_code: this.recieverDetails.state_code.state_code,
            country_code: this.recieverDetails.country_code.iso_alpha3,
          });
          console.log(
            "this.beneficiaryReqBody.country_code",
            this.recieverDetails.country_code.iso_alpha3
          );
          this.stateSelected = {
            state_code: this.recieverDetails.state_code.state_code,
            state_name: this.recieverDetails.state_code.name,
          };
        }
      } catch (error) {
        this.loader = false;
        console.log("error", error);
      }
      // await this.$store.dispatch("Authentication/getStateByIso3", country.iso3);
      // if (Array.isArray(this.state_list.response.data)) {
      //   this.state_options = this.state_list.response.data.map((i) => {
      //     return {
      //       val: {
      //         state_code: i.state_code,
      //         state_name: i.name,
      //       },
      //       txt: i.name,
      //     };
      //   });
      // //-----------mapping values while updating Beneficiary------------//
      // if (this.$route.query.Remittee_ID && this.recieverDetails.state_code.name) {
      //   this.onChangeState({
      //     state_code: this.recieverDetails.state_code.state_code,
      //     country_code: this.recieverDetails.country_code.iso_alpha3,
      //   });
      //   console.log(
      //     "this.beneficiaryReqBody.country_code",
      //     this.recieverDetails.country_code.iso_alpha3
      //   );
      //   this.stateSelected = {
      //     state_code: this.recieverDetails.state_code.state_code,
      //     state_name: this.recieverDetails.state_code.name,
      //   };
      // }
      // }
    },
    async onChangeState(state) {
      console.log("state", state);
      let id = {
        state_code: state.state_code,
        country_code: this.beneficiaryReqBody.country_code,
      };
      try {
        const responseData = await commonService.getCityByIso3(id);
        this.city_options = responseData.data.data.map((i) => {
          return {
            val: {
              id: i.id,
              name: i.name,
            },
            txt: i.name,
          };
        });
        if (
          this.$route.query.Remittee_ID &&
          this.recieverDetails.city_code.name
        ) {
          this.citySelected = {
            id: this.recieverDetails.city_code.id,
            name: this.recieverDetails.city_code.name,
          };
        }
        if (this.beneficiaryReqBody.Remittee_city) {
          this.cityValue = this.beneficiaryReqBody.Remittee_city;
        }
      } catch (error) {
        this.loader = false;
        console.log("error", error);
      }
      // let id = {
      //   state: state.state_code,
      //   country: this.country_code.iso3,
      // };
      // await this.$store.dispatch("Authentication/getCityByIso3", id);
      // if (Array.isArray(this.city_list.response.data)) {
      //   this.city_options = this.city_list.response.data.map((i) => {
      //     return {
      //       val: {
      //         id: i.id,
      //         name: i.name,
      //       },
      //       txt: i.name,
      //     };
      //   });
      //   //-----------mapping values while updating Beneficiary------------//
      //   if (this.$route.query.Remittee_ID && this.recieverDetails.city_code.name) {
      //     this.citySelected = {
      //       id: this.recieverDetails.city_code.id,
      //       name: this.recieverDetails.city_code.name,
      //     };
      //   }
      //   if (this.beneficiaryReqBody.Remittee_city) {
      //     this.cityValue = this.beneficiaryReqBody.Remittee_city;
      //   }
      // }
      // if (this.city_options.length === 0) {
      //   this.haveCities = false;
      // } else {
      //   this.haveCities = true;
      //   console.log("have list");
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
