<template>
  <section>
    <v-container>
      <loading
        :active="loader"
        :is-full-page="fullPage"
        :opacity="0.7"
        color="#ff6b00"
        :height="height"
        :width="width"
      />

      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-row justify="center">
        <v-col
          cols="12"
          v-if="isTrans == 'true'"
          class="d-flex w-100 justify-content-end"
        >
          <v-btn color="primary" @click="onClickCancel">
            {{ $t("cancel_beneficary") }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="d-flex justify-content-center summary_heading mb-3"
        >
          {{ $t("please_check_the_below_given_information_beneficary") }}
        </v-col>
        <!-- <v-col cols="12" md="12" 
          class="d-flex justify-content-center font-weight-bold">
          Summary
        </v-col> -->

        <v-expansion-panels accordion v-model="panelOpened">
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <strong>{{ $t("paymentmode_beneficary") }} </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-auto account-main-div">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">
                        {{ $t("beneficary_country") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.country_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        {{ $t("payment_method_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.payment_mode_name }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.payment_code == 'B'">
                      <td class="text-left">
                        {{ $t("bank_name_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.bank_name }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.payment_code == 'B'">
                      <td class="text-left">
                        {{ $t("branch_code_personal") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.branch_code }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.payment_code == 'B'||beneficiaryReqBody.payment_code == 'W'">
                      <td class="text-left">
                        {{ $t("account_number_personal") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.bank_account_no }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        beneficiaryReqBody.payment_code != 'B' &&
                        beneficiaryReqBody.payment_code != 'A'
                      "
                    >
                      <td class="text-left">
                        {{ $t("payoutLocation") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.location_name }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        beneficiaryReqBody.payment_code === 'A' &&
                        beneficiaryReqBody.country_name === 'Indonesia'
                      "
                    >
                      <td class="text-left">
                        {{ $t("Policy_Number") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.policy_number }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <strong> {{ $t("personal_information_beneficary") }} </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-auto account-main-div">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">
                        {{ $t("firstname_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.first_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        {{ $t("lastname_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.last_name }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.relationship_code">
                      <td class="text-left">
                        {{ $t("relationship_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.relationship_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        {{ $t("contact_number_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.phone_number }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        {{ $t("email_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.email_id }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.id_type">
                      <td class="text-left">
                        {{ $t("id_type_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{
                          beneficiaryReqBody.id_type_name &&
                          beneficiaryReqBody.id_type_name[0]
                            ? beneficiaryReqBody.id_type_name[0]
                            : ""
                        }}({{ beneficiaryReqBody.id_type }})
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.id_no">
                      <td class="text-left">
                        {{ $t("IdNumber") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.id_no }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <strong>{{ $t("address_information") }} </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-auto account-main-div">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="text-left">
                        {{ $t("address_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.address }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        {{ $t("country_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.country_name }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.state_code">
                      <td class="text-left">
                        {{ $t("state_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.state_name }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.city_code">
                      <td class="text-left">
                        {{ $t("city_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.city_name }}
                      </td>
                    </tr>
                    <tr v-if="beneficiaryReqBody.postal_code">
                      <td class="text-left">
                        {{ $t("postalcode_beneficary") }}
                      </td>
                      <td class="text-left">
                        {{ beneficiaryReqBody.postal_code }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="onClickBack"
            outlined
            ><v-icon dark left>mdi-arrow-left</v-icon>
            {{ $t("backLabel") }}</v-btn
          >
          <v-btn
            class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="onClickNext()"
            outlined
          >
            <div class="button-loader" v-if="loader">
              <span class="spinner-border spinner-border-sm"></span>
            </div>
            <div v-if="$route.query.Remittee_ID">
              {{ $t("update_beneficary") }}
            </div>
            <div v-else>{{ $t("submit_beneficary") }}</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
  name: "Summary",
  components: {
    Loading,
  },
  data() {
    return {
      name: "",
      isSubmitted: false,
      fullPage: true,
      height: 100,
      width: 100,
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      BussinessUserData: {},
      method_options: null,
      panelOpened: 0,
      payment_code_list: [
        { text: "Account Deposit to Other Bank", value: "11" },
        { text: "Cash Payment", value: "12" },
        { text: "Home Delivery", value: "13" },
        { text: "E/Mobile-Wallet", value: "14" },
        { text: "Digital Currency", value: "15" },
      ],
    };
  },
  props: {
    beneficiaryReqBody: {
      type: Object,
      default() {
        return {};
      },
    },
    isTrans: {
      type: String,
      default: "",
    },
    loader: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  mounted() {
    console.log(`summary page: ${JSON.stringify(this.beneficiaryReqBody)}`);
  },
  methods: {
    onClickCancel() {
      this.$router.push("/transaction/3");
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      this.$emit("onClickNext", this.beneficiaryReqBody);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-password {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-confirm {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-swift {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.summary_heading {
  color: $primary;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin: auto;
  }
}
</style>
